import {
	Box,
	Card,
	CardHeader,
	Divider,
	Stack,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { JSX } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import LootBagItemFragment from './LootBagItemFragment'

const LootBag: React.FC = () => {
	const lootBag = useSelector(
		(state: RootState) => state.profile.entity.lootBag
	)

	if (lootBag.length === 0) {
		return null
	}

	// Map loot bag to groups of 8 items
	const itemRows = lootBag.reduce(
		(acc: JSX.Element[][], lootBagItem, index) => {
			const rowNumber = Math.floor(index / 8)
			if (!acc[rowNumber]) {
				acc[rowNumber] = []
			}
			acc[rowNumber].push(
				<LootBagItemFragment
					key={`lootBagItem-${lootBagItem.ID}`}
					lootBagItem={lootBagItem}
				/>
			)
			return acc
		},
		[]
	)

	// Map item groups to rows
	const rows = itemRows.map((itemRow, index) => (
		<Stack key={`row-${index}`} flexDirection="row">
			{itemRow}
		</Stack>
	))

	return (
		<>
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					title={
						<Typography variant="subtitle2">
							{t('app:Armory.lootBag')}
						</Typography>
					}
				/>
				<Divider />
				<Box p={0.5}>{rows}</Box>
			</Card>
		</>
	)
}

export default LootBag
