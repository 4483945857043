import {
	ARMOR_UPGRADES,
	ITEMS_MAP,
	WEAPON_UPGRADES,
	getMembershipStatus,
	getProfileItemArmor,
	getProfileItemDamage,
	getProfileItemSalvageValue,
	getProfileItemSellValue
} from '@allardvanderouw/mercenariesonline-commons'
import {
	ArmorUpgrade,
	Item,
	ItemSlot,
	ProfileInventorySlot,
	ProfileItemSource,
	ProfilesArmoryCollectItemFromLootBagRequestBody,
	ProfilesArmoryEquipItemFromInventoryRequestBody,
	ProfilesArmoryMoveItemFromInventoryRequestBody,
	ProfilesArmorySalvageItemRequestBody,
	ProfilesArmorySellItemRequestBody,
	ProfilesArmoryUpgradeItemRequestBody,
	UserMembershipStatus,
	WeaponUpgrade
} from '@allardvanderouw/mercenariesonline-types'
import {
	faBorderNone,
	faCog,
	faDollarSign
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Button,
	ButtonBase,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Menu,
	MenuItem,
	MenuProps,
	Stack,
	SvgIcon,
	Tooltip,
	TooltipProps,
	Typography,
	menuClasses,
	styled,
	tooltipClasses
} from '@mui/material'
import { green, grey, red } from '@mui/material/colors'
import { Box } from '@mui/system'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { JSX, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../redux'
import {
	collectArmoryItemFromLootBag,
	equipArmoryItemFromInventory,
	moveArmoryItemFromInventory,
	salvageArmoryItem,
	sellArmoryItem,
	upgradeArmoryItem
} from '../../../../redux/profile/thunks'
import { ItemSource, ProfileStateStatus } from '../../../../types'
import {
	getItemDescription,
	getItemSlotDescription
} from '../../../../util/items/get-item-description'
import { getItemQualityColor } from '../../../../util/items/get-item-quality-color'
import ConfirmDialog from '../../../Shared/ConfirmDialog'

interface ProfileItemFragmentProps {
	source: ItemSource
	size: '40px' | '60px'
	item: ProfileInventorySlot
	liftedInventoryKey?: string | null
	setLiftedInventoryKey?: (inventoryKey: string | null) => void
	inventoryKey?: string
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: 'rgb(38, 50, 56, 0.9)'
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: 'rgb(38, 50, 56, 0.9)'
	}
}))

const StyledMenu = styled(({ ...props }: MenuProps) => <Menu {...props} />)(
	({ theme }) => ({
		[`& .${menuClasses.paper}`]: {
			backgroundColor: 'rgb(38, 50, 56, 1)'
		}
	})
)

const ProfileItemFragment: React.FC<ProfileItemFragmentProps> = ({
	item,
	source,
	size,
	liftedInventoryKey,
	setLiftedInventoryKey,
	inventoryKey
}) => {
	const dispatch = useAppDispatch()
	const status = useSelector((state: RootState) => state.profile.status)
	const cash = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.cash
	)
	const weaponParts = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.weaponParts
	)
	const membershipPeriods = useSelector(
		(state: RootState) => state.user.membershipPeriods
	)
	const equipment = useSelector(
		(state: RootState) => state.profile.entity.equipment
	)
	const membershipStatus = getMembershipStatus(membershipPeriods)

	const [isSalvaging, setIsSalvaging] = useState(false)
	const [isSalvageConfirmationDialogOpen, setIsSalvageConfirmationDialogOpen] =
		useState(false)
	const [isUpgrading, setIsUpgrading] = useState(false)
	const [isUpgradeConfirmationDialogOpen, setIsUpgradeConfirmationDialogOpen] =
		useState(false)
	const [isSelling, setIsSelling] = useState(false)
	const [isSellConfirmationDialogOpen, setIsSellConfirmationDialogOpen] =
		useState(false)
	const [errorText, setErrorText] = useState('')
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [nextWeaponUpgrade, setNextWeaponUpgrade] = useState<
		WeaponUpgrade | undefined
	>(undefined)
	const [nextArmorUpgrade, setNextArmorUpgrade] = useState<
		ArmorUpgrade | undefined
	>(undefined)

	useEffect(() => {
		if (item) {
			const gameItem = ITEMS_MAP.get(item.itemID) as Item
			let newNextWeaponUpgrade = undefined
			if (
				[
					ItemSlot.PrimaryWeapon,
					ItemSlot.SecondaryWeapon,
					ItemSlot.SpecialWeapon
				].includes(gameItem.slot)
			) {
				newNextWeaponUpgrade = WEAPON_UPGRADES.find(
					(weaponUpgrade) =>
						weaponUpgrade.tier === gameItem.tier &&
						weaponUpgrade.level === (item?.level || 0) + 1
				)
			}
			setNextWeaponUpgrade(newNextWeaponUpgrade)
		}
	}, [item])

	useEffect(() => {
		if (item) {
			const gameItem = ITEMS_MAP.get(item.itemID) as Item
			let newNextArmorUpgrade = undefined
			if (
				[
					ItemSlot.Head,
					ItemSlot.Chest,
					ItemSlot.Legs,
					ItemSlot.Hands,
					ItemSlot.Feet
				].includes(gameItem.slot)
			) {
				newNextArmorUpgrade = ARMOR_UPGRADES.find(
					(armorUpgrade) =>
						armorUpgrade.tier === gameItem.tier &&
						armorUpgrade.level === (item?.level || 0) + 1
				)
			}
			setNextArmorUpgrade(newNextArmorUpgrade)
		}
	}, [item])

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	const handleDrop = async () => {
		try {
			if (
				source === ItemSource.Inventory &&
				setLiftedInventoryKey &&
				inventoryKey &&
				liftedInventoryKey
			) {
				const payload: ProfilesArmoryMoveItemFromInventoryRequestBody = {
					fromInventoryKey: liftedInventoryKey,
					toInventoryKey: inventoryKey
				}
				const wrappedResult = await dispatch(
					moveArmoryItemFromInventory(payload)
				)
				unwrapResult(wrappedResult) // unwrap to catch errors
				setLiftedInventoryKey(null)
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		}
	}

	const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (liftedInventoryKey) {
			handleDrop()
		} else {
			setAnchorEl(event.currentTarget)
		}
	}

	const handleOptionsClose = () => {
		setAnchorEl(null)
	}

	const handleMoveClick = () => {
		handleOptionsClose()
		if (setLiftedInventoryKey && inventoryKey) {
			setLiftedInventoryKey(inventoryKey)
		}
	}

	const handleCollectClick = async () => {
		handleOptionsClose()
		try {
			if (source === ItemSource.LootBag && item) {
				const payload: ProfilesArmoryCollectItemFromLootBagRequestBody = {
					itemID: item.ID
				}
				const wrappedResult = await dispatch(
					collectArmoryItemFromLootBag(payload)
				)
				unwrapResult(wrappedResult) // unwrap to catch errors
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		}
	}

	const handleEquipClick = async () => {
		handleOptionsClose()
		try {
			if (item && source === ItemSource.Inventory && inventoryKey) {
				const gameItem = ITEMS_MAP.get(item.itemID) as Item
				if (
					membershipStatus === UserMembershipStatus.NonMember &&
					gameItem.slot === ItemSlot.SpecialWeapon
				) {
					setErrorText(t('app:Armory.equipSpecialWeaponRequiresMembership'))
					return
				}
				if (
					membershipStatus === UserMembershipStatus.NonMember &&
					gameItem.slot === ItemSlot.Head
				) {
					setErrorText(t('app:Armory.equipHeadArmorRequiresMembership'))
					return
				}

				const payload: ProfilesArmoryEquipItemFromInventoryRequestBody = {
					inventoryKey
				}
				const wrappedResult = await dispatch(
					equipArmoryItemFromInventory(payload)
				)
				unwrapResult(wrappedResult) // unwrap to catch errors
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		}
	}

	const handleSell = async () => {
		try {
			if (item && source === ItemSource.Inventory) {
				setIsSelling(true)
				const payload: ProfilesArmorySellItemRequestBody = {
					itemID: item.ID,
					source: ProfileItemSource.Inventory
				}
				const wrappedResult = await dispatch(sellArmoryItem(payload))
				unwrapResult(wrappedResult) // unwrap to catch errors
			}
			if (item && source === ItemSource.LootBag) {
				setIsSelling(true)
				const payload: ProfilesArmorySellItemRequestBody = {
					itemID: item.ID,
					source: ProfileItemSource.LootBag
				}
				const wrappedResult = await dispatch(sellArmoryItem(payload))
				unwrapResult(wrappedResult) // unwrap to catch errors
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			setIsSelling(false)
			if (isSellConfirmationDialogOpen) {
				setIsSellConfirmationDialogOpen(false)
			}
		}
	}

	const handleSellClick = (event: any) => {
		handleOptionsClose()
		if (event.shiftKey) {
			handleSell()
		} else {
			setIsSellConfirmationDialogOpen(true)
		}
	}

	const handleSellConfirmClick = async () => {
		handleSell()
	}

	const handleSellCancelClick = async () => {
		setIsSellConfirmationDialogOpen(false)
	}

	const handleSalvage = async () => {
		try {
			if (item && source === ItemSource.Inventory) {
				setIsSalvaging(true)
				const payload: ProfilesArmorySalvageItemRequestBody = {
					itemID: item.ID,
					source: ProfileItemSource.Inventory
				}
				const wrappedResult = await dispatch(salvageArmoryItem(payload))
				unwrapResult(wrappedResult) // unwrap to catch errors
			}
			if (item && source === ItemSource.LootBag) {
				setIsSalvaging(true)
				const payload: ProfilesArmorySalvageItemRequestBody = {
					itemID: item.ID,
					source: ProfileItemSource.LootBag
				}
				const wrappedResult = await dispatch(salvageArmoryItem(payload))
				unwrapResult(wrappedResult) // unwrap to catch errors
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			setIsSalvaging(false)
			if (isSalvageConfirmationDialogOpen) {
				setIsSalvageConfirmationDialogOpen(false)
			}
		}
	}

	const handleSalvageClick = (event: any) => {
		handleOptionsClose()
		if (event.shiftKey) {
			handleSalvage()
		} else {
			setIsSalvageConfirmationDialogOpen(true)
		}
	}

	const handleSalvageConfirmClick = async () => {
		handleSalvage()
	}

	const handleSalvageCancelClick = async () => {
		setIsSalvageConfirmationDialogOpen(false)
	}

	const handleUpgradeClick = () => {
		if (nextWeaponUpgrade) {
			const hasEnoughWeaponParts =
				weaponParts >= nextWeaponUpgrade.costs.weaponParts
			if (!hasEnoughWeaponParts) {
				setErrorText(t('app:Generic.notEnoughWeaponParts'))
				return
			}
		}
		if (nextArmorUpgrade) {
			const hasEnoughCash = cash >= nextArmorUpgrade.costs.cash
			if (!hasEnoughCash) {
				setErrorText(t('app:Generic.notEnoughCash'))
				return
			}
		}
		handleOptionsClose()
		setIsUpgradeConfirmationDialogOpen(true)
	}

	const handleUpgradeConfirmClick = async () => {
		try {
			if (item && source === ItemSource.Equipment) {
				setIsUpgrading(true)
				const payload: ProfilesArmoryUpgradeItemRequestBody = {
					itemID: item.ID,
					source: ProfileItemSource.Equipment
				}
				const wrappedResult = await dispatch(upgradeArmoryItem(payload))
				unwrapResult(wrappedResult) // unwrap to catch errors
			}
			if (item && source === ItemSource.Inventory) {
				setIsUpgrading(true)
				const payload: ProfilesArmoryUpgradeItemRequestBody = {
					itemID: item.ID,
					source: ProfileItemSource.Inventory
				}
				const wrappedResult = await dispatch(upgradeArmoryItem(payload))
				unwrapResult(wrappedResult) // unwrap to catch errors
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		} finally {
			setIsUpgrading(false)
			setIsUpgradeConfirmationDialogOpen(false)
		}
	}

	const handleUpgradeCancelClick = async () => {
		setIsUpgradeConfirmationDialogOpen(false)
	}

	let avatar
	let salvageConfirmDialog
	let sellConfirmDialog
	let upgradeConfirmDialog
	let tooltipContent
	let optionsMenu

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	if (item) {
		const qualityColor = getItemQualityColor(item)
		const gameItem = ITEMS_MAP.get(item.itemID) as Item

		let equipedItem
		switch (gameItem.slot) {
			case ItemSlot.PrimaryWeapon:
				equipedItem = equipment.primaryWeapon
				break
			case ItemSlot.SecondaryWeapon:
				equipedItem = equipment.secondaryWeapon
				break
			case ItemSlot.SpecialWeapon:
				equipedItem = equipment.specialWeapon
				break
			case ItemSlot.Head:
				equipedItem = equipment.head
				break
			case ItemSlot.Chest:
				equipedItem = equipment.chest
				break
			case ItemSlot.Legs:
				equipedItem = equipment.legs
				break
			case ItemSlot.Feet:
				equipedItem = equipment.feet
				break
			case ItemSlot.Hands:
				equipedItem = equipment.hands
				break
		}

		const icon = (
			<Box
				height="100%"
				width="100%"
				sx={{
					backgroundImage: `url('/assets/images/items/${gameItem.ID}.png')`,
					backgroundSize: 'contain'
				}}
			/>
		)

		let itemLevel
		if ((item.level || 0) > 0) {
			itemLevel = (
				<Typography variant="subtitle2" color="secondary">
					{t('app:Generic.itemLevel')} {item.level}
				</Typography>
			)
		}

		let slot
		if (gameItem.slot !== ItemSlot.None) {
			slot = (
				<Typography variant="subtitle2" gutterBottom>
					{getItemSlotDescription(gameItem.slot)}
				</Typography>
			)
		}

		let damage
		if (gameItem.stats.damage) {
			const totalDamage = getProfileItemDamage(item)

			let damageDifferenceText
			if (item.ID !== equipedItem?.ID) {
				const equipedItemDamage = equipedItem
					? getProfileItemDamage(equipedItem)
					: 0
				const damageDifference = totalDamage - equipedItemDamage
				damageDifferenceText =
					damageDifference >= 0 ? (
						<Typography component="span" color={green['500']}>
							(+{damageDifference})
						</Typography>
					) : (
						<Typography component="span" color={red['500']}>
							({damageDifference})
						</Typography>
					)
			}
			damage = (
				<Typography>
					{totalDamage} {t('app:Generic.damage').toLowerCase()}{' '}
					{damageDifferenceText}
				</Typography>
			)
		}

		let armor
		if (gameItem.stats.armor) {
			const totalArmor = getProfileItemArmor(item)

			let armorDifferenceText
			if (item.ID !== equipedItem?.ID) {
				const equipedItemArmor = equipedItem
					? getProfileItemArmor(equipedItem)
					: 0
				const armorDifference = totalArmor - equipedItemArmor
				armorDifferenceText =
					armorDifference >= 0 ? (
						<Typography component="span" color={green['500']}>
							(+{armorDifference})
						</Typography>
					) : (
						<Typography component="span" color={red['500']}>
							({armorDifference})
						</Typography>
					)
			}
			armor = (
				<Typography>
					{totalArmor} {t('app:Generic.armor').toLowerCase()}{' '}
					{armorDifferenceText}
				</Typography>
			)
		}

		let sell
		let sellValue = getProfileItemSellValue(item)
		if (sellValue) {
			sell = (
				<Stack flexDirection="row" alignItems="center">
					<Typography variant="caption">
						{t('app:Generic.sell')}: {sellValue}
					</Typography>
					<SvgIcon fontSize="inherit" sx={{ ml: '2px' }}>
						<FontAwesomeIcon icon={faDollarSign} color="#fff" />
					</SvgIcon>
				</Stack>
			)
		}

		let salvage
		let salvageValue = getProfileItemSalvageValue(item)
		if (salvageValue) {
			salvage = (
				<Stack flexDirection="row" alignItems="center">
					<Typography variant="caption">
						{t('app:Generic.salvage')}: {salvageValue}
					</Typography>
					<SvgIcon fontSize="inherit" sx={{ ml: '2px' }}>
						<FontAwesomeIcon icon={faCog} color="#fff" />
					</SvgIcon>
				</Stack>
			)
		}

		let canEquip = false

		let armorUpgradeFragment
		if (
			[
				ItemSlot.Head,
				ItemSlot.Chest,
				ItemSlot.Legs,
				ItemSlot.Hands,
				ItemSlot.Feet
			].includes(gameItem.slot)
		) {
			canEquip = true
			if (nextArmorUpgrade) {
				armorUpgradeFragment = (
					<Stack flexDirection="row" alignItems="center">
						<Typography variant="caption">
							{t('app:Generic.upgrade')}: {nextArmorUpgrade.costs.cash}
						</Typography>
						<SvgIcon fontSize="inherit" sx={{ ml: '2px' }}>
							<FontAwesomeIcon icon={faDollarSign} color="#fff" />
						</SvgIcon>
					</Stack>
				)
			}
		}

		let weaponUpgradeFragment
		if (
			[
				ItemSlot.PrimaryWeapon,
				ItemSlot.SecondaryWeapon,
				ItemSlot.SpecialWeapon
			].includes(gameItem.slot)
		) {
			canEquip = true
			if (nextWeaponUpgrade) {
				weaponUpgradeFragment = (
					<Stack flexDirection="row" alignItems="center">
						<Typography variant="caption">
							{t('app:Generic.upgrade')}: {nextWeaponUpgrade.costs.weaponParts}
						</Typography>
						<SvgIcon fontSize="inherit" sx={{ ml: '2px' }}>
							<FontAwesomeIcon icon={faCog} color="#fff" />
						</SvgIcon>
					</Stack>
				)
			}
		}

		let clickForOptions
		if (optionsMenu) {
			clickForOptions = (
				<Typography variant="caption">
					{t('app:Armory.clickForOptions')}
				</Typography>
			)
		}

		tooltipContent = (
			<Box>
				<Typography variant="h6" sx={{ color: qualityColor }}>
					{getItemDescription(item.itemID)}
				</Typography>
				{itemLevel}
				{slot}
				{damage}
				{armor}
				<Divider sx={{ my: 1 }} />
				{armorUpgradeFragment}
				{weaponUpgradeFragment}
				{salvage}
				{sell}
				{clickForOptions}
			</Box>
		)

		let options: JSX.Element[] = []
		if (source === ItemSource.LootBag) {
			options = options.concat(
				<MenuItem key="collect" onClick={handleCollectClick}>
					{t('app:Generic.collect')}
				</MenuItem>
			)
		}
		if (source === ItemSource.Inventory) {
			options = options.concat(
				<MenuItem key="move" onClick={handleMoveClick}>
					{t('app:Generic.move')}
				</MenuItem>,
				<Divider key="move-divider" />
			)
		}
		if (canEquip && source === ItemSource.Inventory) {
			options = options.concat(
				<MenuItem key="equip" onClick={handleEquipClick}>
					{t('app:Generic.equip')}
				</MenuItem>
			)
		}
		if (
			(nextWeaponUpgrade || nextArmorUpgrade) &&
			(source === ItemSource.Equipment || source === ItemSource.Inventory)
		) {
			options = options.concat(
				<MenuItem key="upgrade" onClick={handleUpgradeClick}>
					{t('app:Generic.upgrade')}
				</MenuItem>
			)
		}
		if (
			salvageValue &&
			(source === ItemSource.Inventory || source === ItemSource.LootBag)
		) {
			options = options.concat(
				<MenuItem key="salvage" onClick={handleSalvageClick}>
					{t('app:Generic.salvage')}
				</MenuItem>
			)
		}
		if (
			sellValue &&
			(source === ItemSource.Inventory || source === ItemSource.LootBag)
		) {
			options = options.concat(
				<MenuItem key="sell" onClick={handleSellClick}>
					{t('app:Generic.sell')}
				</MenuItem>
			)
		}

		if (options.length > 0) {
			optionsMenu = (
				<StyledMenu
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={handleOptionsClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
				>
					{options}
				</StyledMenu>
			)
		}

		salvageConfirmDialog = (
			<ConfirmDialog
				isOpen={isSalvageConfirmationDialogOpen}
				isProcessing={isSalvaging}
				cancel={handleSalvageCancelClick}
				cancelButtonText={t('app:Generic.cancel')}
				confirm={handleSalvageConfirmClick}
				confirmButtonText={t('app:Generic.salvage')}
				descriptionText={
					<>
						{t('app:Generic.confirmSalvageDescriptionPart1')}{' '}
						<Typography
							component="span"
							sx={{ color: qualityColor, fontWeight: 'bold' }}
						>
							{getItemDescription(item.itemID)}
						</Typography>{' '}
						{t('app:Generic.confirmSalvageDescriptionPart2')}{' '}
						<Typography
							component="span"
							sx={{ fontWeight: 'bold' }}
							color="secondary"
						>
							{salvageValue.toLocaleString()}
						</Typography>{' '}
						{t('app:Generic.confirmSalvageDescriptionPart3')}
					</>
				}
				titleText={t('app:Generic.confirmSalvageTitle')}
			/>
		)

		sellConfirmDialog = (
			<ConfirmDialog
				isOpen={isSellConfirmationDialogOpen}
				isProcessing={isSelling}
				cancel={handleSellCancelClick}
				cancelButtonText={t('app:Generic.cancel')}
				confirm={handleSellConfirmClick}
				confirmButtonText={t('app:Generic.sell')}
				descriptionText={
					<>
						{t('app:Generic.confirmSellDescriptionPart1')}{' '}
						<Typography
							component="span"
							sx={{ color: qualityColor, fontWeight: 'bold' }}
						>
							{getItemDescription(item.itemID)}
						</Typography>{' '}
						{t('app:Generic.confirmSellDescriptionPart2')}{' '}
						<Typography
							component="span"
							sx={{ fontWeight: 'bold' }}
							color="secondary"
						>
							{sellValue.toLocaleString()}
						</Typography>{' '}
						{t('app:Generic.confirmSellDescriptionPart3')}
					</>
				}
				titleText={t('app:Generic.confirmSellTitle')}
			/>
		)

		upgradeConfirmDialog = (
			<ConfirmDialog
				isOpen={isUpgradeConfirmationDialogOpen}
				isProcessing={isUpgrading}
				cancel={handleUpgradeCancelClick}
				cancelButtonText={t('app:Generic.cancel')}
				confirm={handleUpgradeConfirmClick}
				confirmButtonText={t('app:Generic.upgrade')}
				descriptionText={
					<>
						{t('app:Generic.confirmUpgradeDescriptionPart1')}{' '}
						<Typography
							component="span"
							sx={{ color: qualityColor, fontWeight: 'bold' }}
						>
							{getItemDescription(item.itemID)}
						</Typography>{' '}
						{t('app:Generic.confirmUpgradeDescriptionPart2')}{' '}
						<Typography
							component="span"
							sx={{ fontWeight: 'bold' }}
							color="secondary"
						>
							{nextArmorUpgrade &&
								getProfileItemArmor({
									...item,
									level: (item.level || 0) + 1
								}).toLocaleString()}
							{nextWeaponUpgrade &&
								getProfileItemDamage({
									...item,
									level: (item.level || 0) + 1
								}).toLocaleString()}
						</Typography>{' '}
						{nextArmorUpgrade &&
							t('app:Generic.confirmUpgradeDescriptionPart3Armor')}
						{nextWeaponUpgrade &&
							t('app:Generic.confirmUpgradeDescriptionPart3Weapon')}{' '}
						<Typography
							component="span"
							sx={{ fontWeight: 'bold' }}
							color="secondary"
						>
							{nextArmorUpgrade && nextArmorUpgrade.costs.cash.toLocaleString()}
							{nextWeaponUpgrade &&
								nextWeaponUpgrade.costs.weaponParts.toLocaleString()}
						</Typography>{' '}
						{nextArmorUpgrade &&
							t('app:Generic.confirmUpgradeDescriptionPart4Armor')}
						{nextWeaponUpgrade &&
							t('app:Generic.confirmUpgradeDescriptionPart4Weapon')}
					</>
				}
				titleText={t('app:Generic.confirmUpgradeTitle')}
			/>
		)

		avatar = (
			<Avatar
				variant="rounded"
				sx={{
					width: size,
					height: size,
					backgroundImage: `linear-gradient(${grey['800']}, ${grey['700']})`
				}}
			>
				{icon}
				<Box
					sx={{
						position: 'absolute',
						width: size,
						height: size,
						boxShadow: `0px 0px 4px 4px ${qualityColor} inset`
					}}
				/>
			</Avatar>
		)
	} else {
		avatar = (
			<Avatar
				variant="rounded"
				sx={{
					width: size,
					height: size,
					backgroundImage: `linear-gradient(${grey['800']}, ${grey['700']})`,
					boxShadow: `0px 0px 4px 4px ${grey['500']} inset`
				}}
			>
				<SvgIcon fontSize="medium">
					<FontAwesomeIcon icon={faBorderNone} color={grey['600']} />
				</SvgIcon>
			</Avatar>
		)
	}

	const isEnabled = Boolean(
		status !== ProfileStateStatus.Pending && (liftedInventoryKey || optionsMenu)
	)
	let avatarContainer
	if (isEnabled) {
		avatarContainer = (
			<ButtonBase disabled={!isEnabled} onClick={handleIconClick}>
				{avatar}
			</ButtonBase>
		)
	} else {
		avatarContainer = <>{avatar}</>
	}

	return (
		<>
			{errorDialog}
			{salvageConfirmDialog}
			{sellConfirmDialog}
			{upgradeConfirmDialog}
			{optionsMenu}
			<StyledTooltip followCursor arrow title={tooltipContent || ''}>
				<div>{avatarContainer}</div>
			</StyledTooltip>
		</>
	)
}

export default ProfileItemFragment
