import { LoadingButton } from '@mui/lab'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material'
import { JSX } from 'react'
interface ConfirmDialogProps {
	cancel: () => void
	cancelButtonText: string
	confirm: () => void
	confirmButtonText: string
	descriptionText: string | JSX.Element
	isDestructive?: boolean
	isOpen: boolean
	isProcessing?: boolean
	titleText: string
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
	cancel,
	cancelButtonText,
	confirm,
	confirmButtonText,
	descriptionText,
	isDestructive,
	isOpen,
	isProcessing,
	titleText
}) => {
	let confirmButton
	if (isDestructive) {
		confirmButton = (
			<LoadingButton
				autoFocus
				onClick={confirm}
				variant="contained"
				color="error"
				loading={isProcessing}
			>
				{confirmButtonText}
			</LoadingButton>
		)
	} else {
		confirmButton = (
			<LoadingButton
				autoFocus
				onClick={confirm}
				variant="contained"
				color="primary"
				loading={isProcessing}
			>
				{confirmButtonText}
			</LoadingButton>
		)
	}

	let description
	if (typeof descriptionText === 'string') {
		const descriptionTextLines = descriptionText
			.split(/\r?\n/)
			.map((descriptionTextLine, key) => (
				<span key={`descriptionline-${key}`}>
					{descriptionTextLine}
					<br />
				</span>
			))
		description = (
			<DialogContent>
				<DialogContentText>{descriptionTextLines}</DialogContentText>
			</DialogContent>
		)
	} else {
		description = (
			<DialogContent>
				<DialogContentText>{descriptionText}</DialogContentText>
			</DialogContent>
		)
	}

	return (
		<Dialog open={isOpen} onClose={isProcessing ? undefined : cancel}>
			<DialogTitle>{titleText}</DialogTitle>
			{description}
			<DialogActions>
				<Box sx={{ color: 'text.secondary', mr: 1 }}>
					<Button
						onClick={cancel}
						variant="outlined"
						color="inherit"
						disabled={isProcessing}
					>
						{cancelButtonText}
					</Button>
				</Box>
				{confirmButton}
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmDialog
